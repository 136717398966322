<template>

  <div v-if="data.role_name" class="data_products">
 
      <UiButton                
        :tag="'NuxtLink'"
        :to="'schemes/edit/0'"
        :name="$t('brand_manager_view_tab_schemes_add_button')"
        />  
         
      <div style="clear:both">&nbsp;</div>

      <n-table :single-column="false" :single-line="true" class="schemes_table" v-if="data_schemes">
      
        <thead>
          <tr>
            <th>{{ $t('brand_manager_view_tab_schemes_name') }}</th>
            <th>{{ $t('brand_manager_view_tab_schemes_date') }}</th>
            <th>{{ $t('brand_manager_view_tab_schemes_products') }}</th>
            <th></th>
          </tr>
        </thead>
  
        <tr v-for="item in data_schemes">

          <td class="nm">
             <div class="nm">
               {{ item.name }}
             </div>
          </td>

          <td class="as">
              {{ item.add_date }}
          </td>

          <td class="ac">
              {{ item.cnt_products }}
          </td>

          <td class="ac">   
          
            <UiButton                
                :tag="'NuxtLink'"
                :to="'schemes/edit/' + (item.id ? item.id : '0?action=edit_item&tmp_id=' + item.tmp_id)"
                :ico="'icon-pencil'"
                />
              
            <UiButton
              :type="'red'"
              :ico="'icon-remove'"
              @click="removeSchemeConfirm(item.id || 0,item.tmp_id || 0)"
              /> 

          </td>

        </tr>
      
      </n-table>

    </div>
  
</template>

<script setup>

import { NButton, NButtonGroup, NTable, useDialog, NDialogProvider, useMessage, NMessageProvider } from 'naive-ui'
import { useI18n } from "vue-i18n"

const {$api} = useNuxtApp()
const { t } = useI18n()
const dialog = useDialog()
const message = useMessage()

const props = defineProps({
  data: {
    type: Object    
  }
});

const loadSchemes = () => {
  $api.getB2BSchemes().then(res => {
    if(res){
      data_schemes.value = res;
      return res;
    }
  })
}

const { pending: is_loading,  data: data_schemes } = await useLazyAsyncData('data_schemes', async () => await loadSchemes())


const removeSchemeConfirm = (id,tmp_id) => {
  dialog.error({
    title: 'Delete',
    content: t('brand_manager_view_tab_schemes_delete_confirm'),
    positiveText: 'Yes',
    negativeText: 'Cancel',
    transformOrigin: 'center',
    onPositiveClick: () => {
      removeScheme(id,tmp_id);
    },
    onNegativeClick: () => {}
  })
}

const removeScheme = (id,tmp_id) => {
  if(!id && !tmp_id){
    return;
  }
  
  $api.deleteB2BScheme(id,tmp_id).then(res => {
    loadSchemes();
  }).catch(function (error) {
     message.error(t('brand_manager_view_generic_error'));
  });
}

/*import Utils from '/pages/brands-manager/func/utils.js'

export default { 
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {
      data_schemes: []
    }
  },
  created() {  
    this.loadSchemes();  
  },
  methods: {   
    showErrorNotification: Utils.showErrorNotification,
    loadSchemes(){
      this.$axios.get('/brands-manager/schemes', {
        params: {
          
        }
      })
      .then((response) => { 
        if(response.status === 200){ 
          this.data_schemes = response.data.data;
        }      
      })
      .catch(function (error) { 
        
      });
    },
    removeSchemeConfirm(id,tmp_id){
      this.$buefy.dialog.confirm({
          //title: 'Deleting product',
          message: this.$t('brand_manager_view_tab_schemes_delete_confirm'),
          confirmText: 'Delete',
          type: 'is-danger',
          //hasIcon: true,
          onConfirm: () => this.removeScheme(id,tmp_id)
      })
    },
    removeScheme(id,tmp_id){
      if(!id && !tmp_id){
        return
      }

      var _this = this;
      this.$axios.delete('/brands-manager/schemes/' + id + (tmp_id ? '?tmp_id=' + tmp_id : ''))
      .then((response) => {
        if(response.status === 200){
          if(response.data.code === 200){
            this.loadSchemes();
          }else {
            this.showErrorNotification();
          }
        } 
      })
      .catch(function (error) { 
        _this.showErrorNotification();
      });
    },
  }
}*/
</script>

<style scoped>



/**/
 
</style>

<style>
 
/* schemes_table */

.schemes_table .an{
  vertical-align: middle;
}

.schemes_table .ac{
  width: 150px;
  vertical-align: middle;
  text-align: center;
}

.schemes_table .as{
  width: 200px;
  vertical-align: middle;
}

.schemes_table .al{
  width: 100px;
  vertical-align: middle;
}

.pointer{
  cursor: pointer;
}

</style>